
import { computed, ref, reactive } from 'vue';

// inputs
import Input from './input.vue';
import DropDown from './dropdown.vue';
import Options from './options.vue';
import Textarea from './textarea.vue';

// composites
import useControls from '@/composites/useControls';

// interfaces
import { iProperties } from '@/interfaces/form';

export default {
  name: 'VueFormWizard',
  props: {
    form: {
      type: Array as () => iProperties[],
      required: true
    },
    startAt: {
      type: Number,
      default: 0
    },
    modelValue: {
      type: Object,
      default: {}
    }
  },
  components: {
    Input,
    DropDown,
    Options,
    Textarea
  },
  methods: {
    getComponent() {
      return {
        'input': Input,
        'dropdown': DropDown,
        'options': Options,
        'textarea': Textarea
        // @ts-ignore
      }[this.currentProperty.type.toLowerCase()]
    }
  },
  emits: [
    'next',
    'skip',
    'previous',
    'submit',
    'update:modelValue'
  ],
  data() {
    return {
    }
  },
  setup(props, { emit }) {
    if (!Array.isArray(props.form) || !props.form.length) {
      return {};
    }

    let data = reactive(props.modelValue);

    let currentIndex = ref(props.startAt);

    const getProperty = () => props.form[currentIndex.value];
    const getData = () => data[getProperty().property] || { value: null, valid: null };

    const currentProperty = computed(() => getProperty());
    const currentValue = computed(() => getData().value);
    const currentValid = computed(() => getData().valid);
    const isFirstProperty = computed(() => currentIndex.value === 0);
    const isLastProperty = computed(() => currentIndex.value === props.form.length - 1)

    const { go, failed } = useControls(emit, currentIndex, currentProperty, currentValid, data);

    const controls = reactive({
      previous: {
        go: go.previous,
        isDisabled: computed(() => isFirstProperty.value)
      },
      skip: {
        go: go.skip,
        isDisabled: computed(() => isLastProperty.value || currentProperty.value.required)
      },
      next: {
        go: go.next,
        isDisabled: computed(() => !currentValue.value)
      },
      submit: {
        go: go.submit,
        isDisabled: computed(() => currentProperty.value.required && !currentValue.value)
      }
    })

    const setProperty = (property: string, value: any, valid: boolean | { errors: [] }) => {
      data[property] = {
        value, valid
      }
    };

    return {
      controls,
      data,
      currentIndex,
      currentProperty,
      currentValue,
      currentValid,
      isFirstProperty,
      isLastProperty,
      setProperty,
      showErrors: failed
    }
  }
};

