
// eslint-disable-next-line no-unused-vars
import { ref, Ref } from 'vue';

import useApi from '@/composites/useApi.ts';

// eslint-disable-next-line no-unused-vars
import { iOptionsList } from '@/interfaces/form.d.ts';

export default {
  name: 'VueFormWizardDropdown',
  props: {
    text: {
      type: String
    },
    property: {
      type: String
    },
    value: {
      type: Array
    },
    options: {
      type: Object as () => iOptionsList,
      default: {}
    }
  },
  emits: [
    'set',
    'update:modelValue'
  ],
  setup(props, { emit }) {
    // eslint-disable-next-line
    const options: iOptionsList = props.options;
    const { list, api } = options;

    const data: Ref = ref([]);
    const selected: Ref = ref([]);
    const {
      id: idKey = 'id',
      value: valueKey = 'value'
    } = options.keys || {};

    if (typeof api === 'object') {
      useApi(api).then((response: any) => { data.value = response; });
    }
    else if (Array.isArray(list)) {
      data.value = list;
    }

    const handleChange = (value: any) => {
      if (options.multiSelect) {
        const itemIndex = selected.value.findIndex((obj: any) => obj[idKey] === value[idKey]);

        if (itemIndex > -1) {
          selected.value.splice(itemIndex, 1);
        }
        else {
          selected.value.push(value);
        }
      }
      else {
        selected.value.splice(0, selected.value.length);
        selected.value.push(value);
      }

      emit('set', props.property, selected.value, true);
    };

    const isSelected = (item: any) => props.value && props.value.find((obj: any) => obj[idKey] === item[idKey]);

    if (typeof options.default !== 'undefined') {
      let item: any;

      if (typeof options.default === 'object') {
        item = data.value.find(
          (obj: any) => Object.keys(options.default).every((key: string) => obj[key] === options.default[key])
        );
      }
      else {
        item = data.value[options.default];
      }

      if (item) {
        handleChange(item);
      }
    }

    return {
      data,
      handleChange,
      isSelected,
      valueKey
    };
  }
};
